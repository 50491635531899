import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'
import { Link } from 'gatsby'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const StrapsProject: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/straps-project.jpg)' }}
        ></div>
        <article className="post">
          <h1>Straps project : New features !</h1>
          <p>
            After the launch of the straps project in France (
            <Link to="/02-2021/straps-sales/">
              discover here the rollout plan
            </Link>
            ) we will add to this concept new cross sale modules as well as a
            strap simulator directly within the watches product pages:
          </p>

          <ul>
            <li>
              When a watch is added to the cart
              <LightboxWrapper>
                <a href={'../../img/straps-add-to-bag.jpg'}>
                  <img
                    src={'../../img/straps-add-to-bag.jpg'}
                    className="img-fluid thumbnail"
                  />
                </a>
              </LightboxWrapper>
            </li>
            <li>
              Once you reach the checkout
              <div className="d-flex">
                <LightboxWrapper>
                  <a href={'../../img/straps-checkout1.jpg'}>
                    <img
                      src={'../../img/straps-checkout1.jpg'}
                      className="img-fluid thumbnail mr-2"
                    />
                  </a>
                </LightboxWrapper>
                <LightboxWrapper>
                  <a href={'../../img/straps-checkout2.jpg'}>
                    <img
                      src={'../../img/straps-checkout2.jpg'}
                      className="img-fluid thumbnail"
                    />
                  </a>
                </LightboxWrapper>
              </div>
            </li>
            <li>
              In the empty cart if you already had bought a Longines watch
              previously:
              <LightboxWrapper>
                <a href={'../../img/straps-bag-login.jpg'}>
                  <img
                    src={'../../img/straps-bag-login.jpg'}
                    className="img-fluid thumbnail"
                  />
                </a>
              </LightboxWrapper>
            </li>
            <li>
              Strap simulator inside a watch product page:
              <div className="d-flex">
                <LightboxWrapper>
                  <a href={'../../img/straps-simulator1.jpg'}>
                    <img
                      src={'../../img/straps-simulator1.jpg'}
                      className="img-fluid thumbnail mr-2"
                    />
                  </a>
                </LightboxWrapper>
                <LightboxWrapper>
                  <a href={'../../img/straps-simulator2.jpg'}>
                    <img
                      src={'../../img/straps-simulator2.jpg'}
                      className="img-fluid thumbnail"
                    />
                  </a>
                </LightboxWrapper>
              </div>
            </li>
          </ul>

          <div className="spacer"></div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">Q4 2021 – Q1 2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <blockquote>
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/steven-valente.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:steven.valente@longines.com">
                    steven.valente@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default StrapsProject

export const pageQuery = graphql`
  query StrapsProject_02_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
